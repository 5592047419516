<div
  class="printer-primary-queue-in-focus-container"
  [ngStyle]="{
    background: printerQueue.backgroundColor,
    fontFamily: printerQueue.fontFamily,
    textTransform: printerQueue.textTransform
  }"
>
  <div *ngIf="queues && queues[0]" class="printer-primary-queue-in-focus-primary-container">
    <img *ngIf="printerQueue.showLogo" (click)="refreshPage()" src="{{ printerQueue?.logo }}" />

    <label [ngStyle]="{ color: printerQueue.labelColor }">
      <ng-container *ngIf="printerQueue.label">
        {{ printerQueue.displayTitle }}
      </ng-container>

      <ng-container *ngIf="!printerQueue.label">
        {{ 'getTicketHere' | translate : branchService.details.locale }}
      </ng-container>
    </label>
    <button
      class="printer-primary-queue-in-focus-button"
      (click)="handleQueueButtonClick(queues[0])"
      [ngClass]="{ '--has-subtext': queues[0]?.subtext }"
      [ngStyle]="{
        color: printerQueue.buttonTextColor,
        background: queues[0]?.backgroundColor || printerQueue.buttonBackgroundColor,
        textTransform: printerQueue.textTransform
      }"
    >
      <i></i>
      {{ queues[0]?.name }}
      <span *ngIf="queues[0]?.subtext" class="printer-primary-queue-in-focus-subtext"> {{ queues[0]?.subtext }} </span>
    </button>

    <div *ngIf="printerQueue.qrCodeEnabled" class="qr-wrapper">
      <qr-code
        class="qr-image"
        *ngIf="printerQueue.qrCodeText"
        [value]="[mobileWebAppUrl, printerQueue.qrCodeText].join('')"
        [size]="printerQueue.qrSize"
        [level]="'H'"
        [foreground]="qrCode.qrForeground"
        [background]="qrCode.qrBackground"
      ></qr-code>
    </div>
  </div>

  <div *ngIf="queues?.length > 1" class="printer-primary-queue-in-focus-sub-buttons --queues-{{ queues?.length }}">
    <span class="printer-primary-queue-in-focus-sub-buttons-label">{{
      'otherServices' | translate : branchService.details.locale
    }}</span>
    <button
      *ngFor="let queue of queues.slice(1)"
      (click)="handleQueueButtonClick(queue)"
      [ngClass]="{ '--has-subtext': queue.subtext }"
      [ngStyle]="{
        color: printerQueue.buttonTextColor,
        background: queue.backgroundColor || printerQueue.buttonBackgroundColor,
        textTransform: printerQueue.textTransform
      }"
    >
      {{ queue.name }}
      <span *ngIf="queue.subtext" class="printer-primary-queue-in-focus-subtext"> {{ queue.subtext }} </span>
    </button>
  </div>
</div>

<app-ticket-modal
  *ngIf="showModal"
  [logo]="branchService?.details?.logoOriginal"
  [customization]="{
    tokenFontFamily: printerQueue.tokenFontFamily,
    tokenBackgroundColor: printerQueue.tokenBackgroundColor,
    tokenFontColor: printerQueue.tokenFontColor
  }"
  [branchName]="branchService?.details?.name"
  [loading]="!showToken"
  [tokenNumber]="ticket?.tokenNumber"
>
</app-ticket-modal>
